/*Rayyan*/
.contact-form{
    color: white;
    padding: 30px 0;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contact-outer-container{
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 380px;
}

.contact-outer-container p{
    color:white;
    font-size: 90px;
    font-weight: 700;
    margin-top:20px;
    background-color: #023047;
    text-align: center;
}


.contact-form input{
    font-size: 18px;
    border-radius: 20px;
    padding: 10px;
    background-color: #FFB703;
    border-color: #FFB703;
    margin:2px;
    height: 30px;
}

.contact-form textarea{
    font-size: 18px;
    border-radius: 20px;
    padding: 10px;
    background-color: #FFB703;
    border-color: #FFB703;
    margin:2px;
    height: 110px;
}

.contact-outer-container button{
    width: 75px;
    border-radius: 12px;
    font-size: 15px;
    margin: 10px;
    padding: 10px;
    background-color: #FB8500;
    border-color: #FB8500;
    color: rgb(255, 255, 255);
    font-weight: 400;
}

.contact-outer-container button:hover{
    cursor: pointer;
    transition: background 1100ms;
    filter: brightness(1.1);
}
