/*Rahul*/

body {
  background-color: #023047;
}


.subheader{
  color: #FFFF;
  position: fixed;
  left: 200px;
  margin-top: 170px;

}

.caption{
  color: #FFFF;
  position: fixed;
  left: 235px;
  margin-top: 490px;
}

/* .paragraph{
  background-color: rgb(200, 21, 21);
  color: #023047;
  position: fixed;
  right: 535px;
  margin-top: 225px;
  padding-block: 150px;
  text-align: left;
} */

.line-left{
  width: 60px;
  height: 0.5px;
  background-color: #FFFF;
  color: #FFFF;
  position: fixed;
  margin-top: 503px;
  left: 160px;
}

.login-info h3 {
  color: white;
}
.line-right{
  width: 60px;
  height: 0.5px;
  background-color: #FFFF;
  color: #FFFF;
  position: fixed;
  margin-top: 503px;
  left: 415px;
}
.guest{
  background-color: #FFFF;
  font-size: 30px;
  text-align: center;
  color: #023047;
  border-radius: 10px;
  width: 400px;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.error{
  color: rgb(235, 114, 114);
  position: relative;
}
.email{
  font-size: 20px;
  background-color: #8ecae6;
  color: #023047;
  border-radius: 10px;
  border-style: none;
  width: 300px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
.password{
  font-size: 20px;
  background-color: #8ecae6;
  color: #023047;
  border-radius: 10px;
  border-style: none;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.login{
  background-color: #FFFF ;
  color: #023047;
  width: 90px;
  padding-block: 12px;
  left: 270px;
  font-size: 20px;
  border-radius: 20px;
  transition: 0.3ms;
  border-style: none;
  margin-bottom: 10px;
  margin-top: 10px;
}

.login-info {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.guest button {
  margin-top: 200px;
  background-color: #ffb703;
  border-radius: 10px;
  border-color: white;
  color: white;
  height: 40px;
  width: 100px;
  box-shadow: none;
  border-style: none;
}



.signUp{
  background-color: #FFFF ;
  color: #023047;
  width: 90px;
  padding-block: 12px;
  left: 270px;
  font-size: 20px;
  border-radius: 20px;
  transition: 0.3ms;
  border-style: none;
  margin-bottom: 10px;
}

.browse:hover{
  background-color: #FB8500;
}

.login:hover{
  background-color: #FB8500 ;
  color: #FFFF;
}
.signUp:hover{
  background-color: #FB8500 ;
  color: #FFFF;
}

::placeholder {
  color: #023047;
  font-size: 20px;
  padding-left: 10px;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media (max-width: 1280px) {

}

