body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body{
    width:100%;
}

.NavbarItems {
    background: #023047;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.nav-menu {
    display: flex;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: center;
    margin-right: 2rem;
    margin-left: 2rem;
    padding: 0;
}

.nav-links {
    color:#fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #ffb703;
    border-radius: 10px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease-out;
        justify-content: center;
    }

    .nav-menu.active {
        background:#6668f4;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease-out;
        z-index: 1;
        margin: 0;
        top: 150px;
    }

    .nav-links {
        text-align: center;
        width: 100%;
        display: table;
        padding: 0 2rem 2rem;
        vertical-align: center;
    }

    .nav-links:hover {
        background-color: #7577fa;
        border-radius: 0;

    }

    .navbar-logo {
        position: relative;

        right: 130px;
        -webkit-transform: translate(25%, -10%);
                transform: translate(25%, -10%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%, 50%);
                transform: translate(-100%, 50%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background-color: #fff;
        color: #ffb703;
        transition: 250ms;
    }

    .log-btn{
        display: none;
    }
}

@media (max-width: 1280px) {
    .navbar-logo {
        padding-left: 0;
    }
}

@media (max-width: 500px) {
    .navbar-logo {
        display: flex;
        float: left;
    }
}
/*Amaar*/

:root {
    --primary: #ffb703;

}


.btn {
    padding: 8px 20px;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    left: 0;
}

.btn:hover{
    padding: 8px 20px;
    transition: all 0.5s ease-out;
    background: #fff;
    color: #fb8500;
    transition: 100ms;
    font-weight: bold;
}

.btn--primary{
    background: #ffb703;
    background: var(--primary);
}

.btn--outline{
    border: 1px solid #ffb703;
    border: 1px solid var(--primary);
    background-color: transparent;
    color: #fff;
    padding:8px 20px;
    border-radius: 10px;
    transition: all 0.2s ease;
}

.btn--medium{
    padding: 8px 20px;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
}

.btn--large {
    padding: 12px 24px;
    border-radius: 10px;
    font-size: 20px;
    color: #fff;
}

/* CSS code for the landing page*/
/*Amaar*/


.top-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    background-color: #023047;
}

.top-content h1 {
    text-align: center;
    color: white;
    font-weight: bolder;
    margin-top: 0;
}

.top-content h3 {
    color: white;
    font-weight: bold;
    text-align: center;
    margin: 10px;
}

.foods {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #dcdcdc;
    align-items: center;
    margin-bottom: 10px;
}

.title-1 {
    text-align: center;
    color: white;
    background-color: #ffb707;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-1 h2 {
    margin: 0;
}

.oil, .banana, .flour {
    float: left;
    height: 400px;
    width: 250px;
    text-align: center;
    border: 3px solid #ffb707;
    border-radius: 10px;
    margin: 20px 10px;
    background-color: #023047;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.increase-button, .decrease-button {
    width: 20%;
    color:white;
    font-weight: bolder;
    font-size: large;
    border: none;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background-color: #ffb707;
    margin: 5px;
}

.privacy{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.priv-btn {
    background-color: #ffb707;
    border-radius: 10px;
    border-style: none;
    color: white;
    width: 80px;
    height: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.priv-btn:hover {
    background-color: #fb8500;
}

.increase-button:hover, .decrease-button:hover {
    background-color: white;
    color: #ffb707;
    width: 25%;
}

.not-logged {
    display: none;
}

.privacy{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.priv-btn {
    background-color: #ffb707;
    border-radius: 10px;
    border-style: none;
    color: white;
    width: 80px;
    height: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.priv-btn:hover {
    background-color: #fb8500;
}

.seva-items {
    text-align: center;
    background-color: #8ecae6;
    color: white;
    margin-top: 7px;
    margin-bottom: 7px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.seva-items h3 {
    margin: 5px;
}

@media (max-width: 500px){
    .top-content h1{
        display: flex;
        justify-content: center;
        text-align: center;
    }
}



/*Amaar*/

.main-map {
}

.info {
    width: 200%;
}

.banks a {
    text-decoration: none;
    color: white;
    display: block;
    text-align: center;
    width: 300px;
    font-size: 15px;
    left: 150px;
    margin: 20px;
    padding: 10px;
    background-color: #FFB703;
    border-radius: 10px;
}

.banks {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #dcdcdc;
}

.bank-1:hover {
    background-color: #fb8500;
    box-shadow: 5px 5px #D4D4D4;
}
.bank-2:hover {
    background-color: #fb8500;
    box-shadow: 5px 5px #D4D4D4;
}
.bank-3:hover {
    background-color: #fb8500;
    box-shadow: 5px 5px #D4D4D4;
}

.seva-marker {
    color: green;
    font-size: 15px;
}

.mfb-marker {
    color: red;
    font-size: 15px;
}

.mwc-marker {
    color: blue;
    font-size: 15px;
}

@media screen and (max-width: 900px) {
    .banks {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }

}


/*Rahul*/

.email{
    font-size: 20px;
    background-color: #8ecae6;
    color: #023047;
    border-radius: 10px;
    border-style: none;
    width: 300px;
    padding-block: 10px;
    margin:0;
    left: 180px
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.fields {
    display: flex;
    flex-direction: column;
}


.pinz {
    margin-top: 20px;
}

.stuff {
    display: flex;
    flex-direction: column;
}

.signup-btn {
    width: 40%;
    height: 30px;
    border-radius: 10px;
    background-color: #ffb703;
    color: white;
    margin-top: 50px;
    border-style: none;
    margin-left: 60px;
}

.password{
  font-size: 20px;
  background-color: #8ecae6;
  color: #023047;
  border-radius: 10px;
  border-style: none;
  width: 300px;
  padding-block: 10px;
  margin-top: 330px;
  left: 180px 
}

.fields h2 {
  color: white;
  margin-top: 50px;
  margin-left: 50px;
}

.login-info h2 {
  color: white;
  margin-top: 80px;
}


.error2{
  color: rgb(235, 114, 114);
  position: relative;
  left: 50px;
}

  
.confirm-password{
  font-size: 20px;
  background-color: #8ecae6;
  color: #023047;
  border-radius: 10px;
  border-style: none;
  width: 300px;
  padding-block: 10px;
  left: 180px 
}


.pin {
    border-radius: 10px;
    height: 30px;
    color: #023047;
    background-color: #8ecae6;
}


/*Rahul*/

body {
  background-color: #023047;
}


.subheader{
  color: #FFFF;
  position: fixed;
  left: 200px;
  margin-top: 170px;

}

.caption{
  color: #FFFF;
  position: fixed;
  left: 235px;
  margin-top: 490px;
}

/* .paragraph{
  background-color: rgb(200, 21, 21);
  color: #023047;
  position: fixed;
  right: 535px;
  margin-top: 225px;
  padding-block: 150px;
  text-align: left;
} */

.line-left{
  width: 60px;
  height: 0.5px;
  background-color: #FFFF;
  color: #FFFF;
  position: fixed;
  margin-top: 503px;
  left: 160px;
}

.login-info h3 {
  color: white;
}
.line-right{
  width: 60px;
  height: 0.5px;
  background-color: #FFFF;
  color: #FFFF;
  position: fixed;
  margin-top: 503px;
  left: 415px;
}
.guest{
  background-color: #FFFF;
  font-size: 30px;
  text-align: center;
  color: #023047;
  border-radius: 10px;
  width: 400px;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.error{
  color: rgb(235, 114, 114);
  position: relative;
}
.email{
  font-size: 20px;
  background-color: #8ecae6;
  color: #023047;
  border-radius: 10px;
  border-style: none;
  width: 300px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
.password{
  font-size: 20px;
  background-color: #8ecae6;
  color: #023047;
  border-radius: 10px;
  border-style: none;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.login{
  background-color: #FFFF ;
  color: #023047;
  width: 90px;
  padding-block: 12px;
  left: 270px;
  font-size: 20px;
  border-radius: 20px;
  transition: 0.3ms;
  border-style: none;
  margin-bottom: 10px;
  margin-top: 10px;
}

.login-info {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.guest button {
  margin-top: 200px;
  background-color: #ffb703;
  border-radius: 10px;
  border-color: white;
  color: white;
  height: 40px;
  width: 100px;
  box-shadow: none;
  border-style: none;
}



.signUp{
  background-color: #FFFF ;
  color: #023047;
  width: 90px;
  padding-block: 12px;
  left: 270px;
  font-size: 20px;
  border-radius: 20px;
  transition: 0.3ms;
  border-style: none;
  margin-bottom: 10px;
}

.browse:hover{
  background-color: #FB8500;
}

.login:hover{
  background-color: #FB8500 ;
  color: #FFFF;
}
.signUp:hover{
  background-color: #FB8500 ;
  color: #FFFF;
}

::-webkit-input-placeholder {
  color: #023047;
  font-size: 20px;
  padding-left: 10px;
}

:-ms-input-placeholder {
  color: #023047;
  font-size: 20px;
  padding-left: 10px;
}

::placeholder {
  color: #023047;
  font-size: 20px;
  padding-left: 10px;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media (max-width: 1280px) {

}


/*Rayyan*/

:root{
    --nav-size: 81px;
    --border: 1px solid rgb(56, 56, 56);
    --border-radius: 12px;
    --speed: 1100ms;
    --faq-top-margin: 90px;
    --send-border-colour: #FB8500;
}



.header{
    height: 81px;
    height: var(--nav-size);
    background-color: #023047;
    padding: 0 1rem;
    border-bottom: 1px solid rgb(56, 56, 56);
    border-bottom: var(--border);
    margin-top: 90px;
    margin-top: var(--faq-top-margin);

}

.header-nav{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;/*experiment with start, end, baseline*/
}

.primary-dropdown{
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-button{
    --button-size: calc(var(--nav-size)*0.5);
    font-size: 35px;
    height: 70px;
    width:1000px;
    background-color: #023047;
    border-radius: 12px;
    border-radius: var(--border-radius);
    padding: 5px;
    margin: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    color: white;
}

a{
    text-decoration: none;
}

.dropdown-button:hover{
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
}

.dropdown{
    position: absolute;
    top: calc(81px*2.13);
    top: calc(var(--nav-size)*2.13);
    width: 1332px;
    background-color: #023047;
    border: 1px solid rgb(56, 56, 56);
    border: var(--border);
    padding: 1rem;
    overflow: hidden;
}

.dropdown-question{
    height: calc(81px*0.5);
    height: calc(var(--nav-size)*0.5);
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: background 1100ms;
    transition: background var(--speed);
    margin: 20px;
    font-size: 25px;
    color: white;
}
.dropdown-question:hover{
    background-color: rgb(123, 128, 128);
    -webkit-filter:brightness(1.1);
            filter:brightness(1.1);
}

.pageHeader{
    height: 10px;
    font-size: 50px;
    margin-left: auto;
}

.question-answer{
    font-size: 20px;
    color: white;
}

/*Rayyan*/
.contact-form{
    color: white;
    padding: 30px 0;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contact-outer-container{
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 380px;
}

.contact-outer-container p{
    color:white;
    font-size: 90px;
    font-weight: 700;
    margin-top:20px;
    background-color: #023047;
    text-align: center;
}


.contact-form input{
    font-size: 18px;
    border-radius: 20px;
    padding: 10px;
    background-color: #FFB703;
    border-color: #FFB703;
    margin:2px;
    height: 30px;
}

.contact-form textarea{
    font-size: 18px;
    border-radius: 20px;
    padding: 10px;
    background-color: #FFB703;
    border-color: #FFB703;
    margin:2px;
    height: 110px;
}

.contact-outer-container button{
    width: 75px;
    border-radius: 12px;
    font-size: 15px;
    margin: 10px;
    padding: 10px;
    background-color: #FB8500;
    border-color: #FB8500;
    color: rgb(255, 255, 255);
    font-weight: 400;
}

.contact-outer-container button:hover{
    cursor: pointer;
    transition: background 1100ms;
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
}

