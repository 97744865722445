body{
    width:100%;
}

.NavbarItems {
    background: #023047;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.nav-menu {
    display: flex;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: center;
    margin-right: 2rem;
    margin-left: 2rem;
    padding: 0;
}

.nav-links {
    color:#fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #ffb703;
    border-radius: 10px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease-out;
        justify-content: center;
    }

    .nav-menu.active {
        background:#6668f4;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease-out;
        z-index: 1;
        margin: 0;
        top: 150px;
    }

    .nav-links {
        text-align: center;
        width: 100%;
        display: table;
        padding: 0 2rem 2rem;
        vertical-align: center;
    }

    .nav-links:hover {
        background-color: #7577fa;
        border-radius: 0;

    }

    .navbar-logo {
        position: relative;

        right: 130px;
        transform: translate(25%, -10%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 50%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background-color: #fff;
        color: #ffb703;
        transition: 250ms;
    }

    .log-btn{
        display: none;
    }
}

@media (max-width: 1280px) {
    .navbar-logo {
        padding-left: 0;
    }
}

@media (max-width: 500px) {
    .navbar-logo {
        display: flex;
        float: left;
    }
}